import { Box, Typography } from '@mui/material';

const RakutenTestPage = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            gap: 2
        }}>

            <Typography>
                RakutenTest123...
            </Typography>
        </Box>
    );
}

export default RakutenTestPage;