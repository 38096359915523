import React from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    Paper,
    Card,
    CardContent,
    useTheme,
    useMediaQuery,
    Link,
} from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import TimelineIcon from '@mui/icons-material/Timeline';
import InsightsIcon from '@mui/icons-material/Insights';
import SpeedIcon from '@mui/icons-material/Speed';
import NotificationsIcon from '@mui/icons-material/Notifications';
import GrowingInfantLogo from '../assets/growing_infant_logo.png';
import HeroSectionSvg from '../assets/hero_section.png';
import BenefitsSectionSvg from '../assets/benefits_section.svg';
import { IsLoggedIn } from '../services/AuthService';

const Landing = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const isUserLoggedIn = IsLoggedIn();

    const features = [
        {
            icon: <TimelineIcon sx={{ fontSize: 40, color: '#4caf50' }} />,
            title: 'Accurate Tracking',
            description: 'Track your child\'s growth with professional-grade accuracy and detailed measurements.'
        },
        {
            icon: <InsightsIcon sx={{ fontSize: 40, color: '#4caf50' }} />,
            title: 'Visual Insights',
            description: 'Clear, visual charts and percentile tracking to understand your child\'s growth journey.'
        },
        {
            icon: <SpeedIcon sx={{ fontSize: 40, color: '#4caf50' }} />,
            title: 'Quick & Easy',
            description: 'Save time with our intuitive interface. Record and access measurements in seconds.'
        },
        {
            icon: <NotificationsIcon sx={{ fontSize: 40, color: '#4caf50' }} />,
            title: 'Growth Alerts',
            description: 'Stay informed with timely notifications about your child\'s growth milestones.'
        }
    ];

    return (
        <Box>
            {/* Header */}
            <Box 
                sx={{ 
                    bgcolor: 'white',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                    position: 'fixed',
                    width: '100%',
                    top: 0,
                    zIndex: 1000
                }}
            >
                <Container maxWidth="xl">
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        py: 2
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <img
                                src={GrowingInfantLogo}
                                alt="Growing Infant Logo"
                                style={{
                                    height: '48px',
                                    width: 'auto'
                                }}
                            />
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: 600,
                                    color: 'text.primary'
                                }}
                            >
                                Growing Infant
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            onClick={() => {isUserLoggedIn ? navigate('/dashboard') : navigate('/auth')}}
                            sx={{
                                bgcolor: '#4caf50',
                                '&:hover': {
                                    bgcolor: '#43a047'
                                },
                                textTransform: 'none',
                                px: 4
                            }}
                        >
                            {isUserLoggedIn ? 'Dashboard' : 'Get Started'}
                        </Button>
                    </Box>
                </Container>
            </Box>

            {/* Hero Section */}
            <Box
                sx={{
                    bgcolor: '#e0f7ea',
                    pt: { xs: 12, md: 16 },
                    pb: { xs: 8, md: 12 }
                }}
            >
                <Container maxWidth="xl">
                    <Grid container spacing={6} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography 
                                variant="h2" 
                                sx={{ 
                                    fontWeight: 'bold',
                                    mb: 3,
                                    fontSize: { xs: '2.5rem', md: '3.5rem' }
                                }}
                            >
                                Track Your Child's Growth Journey with Confidence
                            </Typography>
                            <Typography 
                                variant="h6" 
                                color="text.secondary"
                                sx={{ mb: 4 }}
                            >
                                Professional-grade growth tracking made simple for parents.
                                Monitor your child's development with accurate measurements and
                                visual insights.
                            </Typography>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => navigate('/auth')}
                                sx={{
                                    bgcolor: '#4caf50',
                                    '&:hover': {
                                        bgcolor: '#43a047'
                                    },
                                    textTransform: 'none',
                                    px: 6,
                                    py: 2,
                                    fontSize: '1.1rem'
                                }}
                            >
                                Start Tracking Now
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box
                                component="img"
                                src={HeroSectionSvg} // Add your hero image
                                alt="Growth tracking illustration"
                                sx={{
                                    width: '100%',
                                    maxWidth: 600,
                                    height: 'auto'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Features Section */}
            <Container maxWidth="xl" sx={{ py: 8 }}>
                <Typography
                    variant="h3"
                    align="center"
                    sx={{ 
                        fontWeight: 'bold',
                        mb: 6,
                        fontSize: { xs: '2rem', md: '2.5rem' }
                    }}
                >
                    Everything You Need to Track Growth
                </Typography>
                <Grid container spacing={4}>
                    {features.map((feature, index) => (
                        <Grid item xs={12} md={6} lg={3} key={index}>
                            <Card
                                elevation={0}
                                sx={{
                                    height: '100%',
                                    borderRadius: 2,
                                    border: '1px solid rgba(0, 0, 0, 0.12)'
                                }}
                            >
                                <CardContent sx={{ p: 4 }}>
                                    <Box sx={{ mb: 2 }}>
                                        {feature.icon}
                                    </Box>
                                    <Typography
                                        variant="h6"
                                        sx={{ 
                                            fontWeight: 600,
                                            mb: 1
                                        }}
                                    >
                                        {feature.title}
                                    </Typography>
                                    <Typography
                                        color="text.secondary"
                                    >
                                        {feature.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {/* Benefits Section */}
            <Box sx={{ bgcolor: '#f8f9fa', py: 8 }}>
                <Container maxWidth="xl">
                    <Grid container spacing={6} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Box
                                component="img"
                                src={BenefitsSectionSvg}  // Add your benefits image
                                alt="Growth tracking benefits"
                                sx={{
                                    width: '100%',
                                    maxWidth: 500,
                                    height: 'auto'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h3"
                                sx={{ 
                                    fontWeight: 'bold',
                                    mb: 4,
                                    fontSize: { xs: '2rem', md: '2.5rem' }
                                }}
                            >
                                Why Parents Choose Growing Infant
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                <Paper 
                                    elevation={0}
                                    sx={{ 
                                        p: 3,
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        borderRadius: 2
                                    }}
                                >
                                    <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                                        3x More Accurate
                                    </Typography>
                                    <Typography color="text.secondary">
                                        Professional-grade measurements ensure accurate tracking
                                        of your child's growth journey.
                                    </Typography>
                                </Paper>
                                <Paper 
                                    elevation={0}
                                    sx={{ 
                                        p: 3,
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        borderRadius: 2
                                    }}
                                >
                                    <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                                        75% Time Saved
                                    </Typography>
                                    <Typography color="text.secondary">
                                        Quick and easy data entry means less time recording,
                                        more time with your child.
                                    </Typography>
                                </Paper>
                                <Paper 
                                    elevation={0}
                                    sx={{ 
                                        p: 3,
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        borderRadius: 2
                                    }}
                                >
                                    <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                                        Instant Insights
                                    </Typography>
                                    <Typography color="text.secondary">
                                        Access to percentile data and growth charts helps you
                                        understand your child's development at a glance.
                                    </Typography>
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* CTA Section */}
            <Box sx={{ bgcolor: '#4caf50', color: 'white', py: 8 }}>
                <Container maxWidth="md" sx={{ textAlign: 'center' }}>
                    <Typography
                        variant="h3"
                        sx={{ 
                            fontWeight: 'bold',
                            mb: 3,
                            fontSize: { xs: '2rem', md: '2.5rem' }
                        }}
                    >
                        Start Tracking Your Child's Growth Today
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{ 
                            mb: 4,
                            opacity: 0.9
                        }}
                    >
                        Join thousands of parents who trust Growing Infant for their
                        children's growth tracking needs.
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate('/auth')}
                        sx={{
                            bgcolor: 'white',
                            color: '#4caf50',
                            '&:hover': {
                                bgcolor: 'rgba(255, 255, 255, 0.9)'
                            },
                            textTransform: 'none',
                            px: 6,
                            py: 2,
                            fontSize: '1.1rem'
                        }}
                    >
                        Get Started Free
                    </Button>
                </Container>
            </Box>

            {/* Footer */}
            <Box sx={{ bgcolor: '#1a1a1a', color: 'white', py: 4 }}>
                <Container maxWidth="xl">
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 2
                    }}>
                        <Typography>
                            © 2024 Growing Infant. All rights reserved.
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 3 }}>
                            <Typography sx={{ opacity: 0.8, cursor: 'pointer' }}>
                                <Link sx={{ color: 'white' }} component={RouterLink} to="/privacy">Privacy Policy</Link>
                            </Typography>
                            <Typography sx={{ opacity: 0.8, cursor: 'pointer' }}>
                                <Link sx={{ color: 'white' }} component={RouterLink} to="/terms">Terms of Service</Link>
                            </Typography>
                            {/* <Typography sx={{ opacity: 0.8, cursor: 'pointer' }}>
                                Contact Us
                            </Typography> */}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default Landing;