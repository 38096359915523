import { StytchHeadlessClient } from '@stytch/vanilla-js/headless'
import { CreateUser, CreateUserIfNotExists } from './UserService';

if (!process.env.REACT_APP_STYTCH_PUBLIC_TOKEN) {
    throw new Error('Stytch public token is not defined');
}

const stytchClient = new StytchHeadlessClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN)
const stytchPublicToken = process.env.REACT_APP_STYTCH_PUBLIC_TOKEN
const googleOauthUrl = process.env.REACT_APP_GOOGLE_OAUTH_URL
const loginRedirectUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL
const signupRedirectUrl = process.env.REACT_APP_SIGNUP_REDIRECT_URL
  
const LogOut = () => {
    return stytchClient.session.revoke()
        .then((response) => {
            return response;
        }).catch((error) => {
            return error;
        });
};

const IsLoggedIn = () => {
    const currentSession = stytchClient.session.getSync();
    return currentSession !== null;
}

const GetUserId = () => {
    const currentSession = stytchClient.session.getSync();
    return currentSession.user_id;
}

const AuthenticateUser = (email, password) => {
    return stytchClient.passwords.authenticate({
        email: email,
        password: password,
        session_duration_minutes: 525601,
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error;
    });
}

const RegisterUser = (email, password) => {
    return stytchClient.passwords.create({
        email: email,
        password: password,
        session_duration_minutes: 525601,
    }).then((response) => {
        // Add the user in the db
        CreateUser(response.user_id);
        return response;
    }).catch((error) => {
        console.error(error);
        return error;
    });
}

const GetGoogleLoginUrl = () => {
    return `${googleOauthUrl}?public_token=${stytchPublicToken}&login_redirect_url=${loginRedirectUrl}&signup_redirect_url=${signupRedirectUrl}`;
}

const HandleGoogleLogin = async (token, callback) => {
    try {
        const response = await stytchClient.oauth.authenticate(token, {
            session_duration_minutes: 525601
        });

        if (response.status_code === 200) {
            const user_id = response.user_id;
            let user_email = '';
            const user = response.user;
            if (user !== null) {
                const emails = user.emails;
                if (emails !== null && emails.length > 0) {
                    user_email = emails[0].email;
                }
            }

            await CreateUserIfNotExists(response.user_id, user_email); // Add the user in the db if they don't exist
            callback(true);
        } else {
            callback(false);
        }
    } catch (error) {
        console.error(error);
        callback(false);
    }
};


export {AuthenticateUser, GetUserId, RegisterUser, LogOut, IsLoggedIn, GetGoogleLoginUrl, HandleGoogleLogin};