import React, { useCallback, useState, useEffect } from 'react';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import {
    Alert,
    Box,
    TextField,
    Button,
    Typography,
    Container,
    InputAdornment,
    Link,
    CircularProgress,
    Grid,
    Paper,
    Divider
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import GoogleIcon from '@mui/icons-material/Google';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { AuthenticateUser, GetUserId, RegisterUser, GetGoogleLoginUrl, HandleGoogleLogin } from '../services/AuthService';
import { IsPaidUser } from '../services/UserService';
import GrowingInfantLogo from '../assets/growing_infant_logo.png';

// Common styles matching GrowthCalculator
const commonStyles = {
    card: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 1,
        bgcolor: 'white',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
        }
    },
    button: {
        primary: {
            bgcolor: '#4caf50',
            color: 'white',
            '&:hover': {
                bgcolor: '#43a047'
            },
            textTransform: 'none'
        },
        secondary: {
            color: 'text.primary',
            borderColor: 'rgba(0, 0, 0, 0.23)',
            '&:hover': {
                borderColor: 'rgba(0, 0, 0, 0.87)',
                bgcolor: 'rgba(0, 0, 0, 0.04)'
            },
            textTransform: 'none'
        }
    }
};

const Auth = () => {
    const [loginOrSignupState, setLoginOrSignupState] = useState('signup');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const checkPaymentStatusAndRedirect = useCallback(async () => {
        const userId = GetUserId();
        const isPaidUser = await IsPaidUser(userId);
        if (isPaidUser) {
            navigate('/dashboard', { replace: true });
            return;
        }

        navigate('/checkout', { replace: true });
    }, [navigate]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        const checkStatus = async () => {
            await checkPaymentStatusAndRedirect();
          };
      
        if (token) {
            setIsAuthenticating(true);
            HandleGoogleLogin(token, (success) => {
                setIsAuthenticating(false);
                if (success) {
                    // navigate('/dashboard', { replace: true });
                    checkStatus();

                } else {
                    setError(true);
                    setErrorMessage('Google authentication failed');
                }
            });
        }
    }, [navigate, checkPaymentStatusAndRedirect, location]);

    const onAuth = async (e) => {
        e.preventDefault();
        const authFunction = loginOrSignupState === 'login' ? AuthenticateUser : RegisterUser;

        try {
            const response = await authFunction(email, password);
            if (response['status_code'] !== 200) {
                setError(true);
                setErrorMessage(response['error_message']);
            } else {
                // navigate('/dashboard', { replace: true });
                checkPaymentStatusAndRedirect();
            }
        } catch (error) {
            setError(true);
            setErrorMessage(error['error_message'] || 'Authentication failed');
        }
    };

    const handleGoogleLogin = () => {
        const url = GetGoogleLoginUrl();
        window.location.href = url;
    };

    if (isAuthenticating) {
        return (
            <Container>
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center', 
                    height: '100vh',
                    gap: 2
                }}>
                    <CircularProgress sx={{ color: '#4caf50' }} />
                    <Typography variant="body1" color="text.secondary">
                        Authenticating...
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl" sx={{ minHeight: '100vh', p: 0 }}>
            {/* Header */}
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                px: 4,
                py: 2,
                // borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <img
                        src={GrowingInfantLogo}
                        alt="Growing Infant Logo"
                        style={{
                            height: '48px',
                            width: 'auto'
                        }}
                    />
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 600,
                            color: 'text.primary'
                        }}
                    >
                        Growing Infant
                    </Typography>
                </Box>
            </Box>

            <Grid container sx={{ minHeight: 'calc(100vh - 73px)' }}>
                {/* Left side - Growth tracking info */}
                <Grid item xs={12} md={6} sx={{
                    bgcolor: '#e0f7ea',
                    p: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                    <Typography variant="h3" sx={{
                        fontWeight: 'bold',
                        mb: 4,
                        color: '#1a1a1a',
                        maxWidth: '500px'
                    }}>
                        Track Your Child's Growth Journey with Confidence
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Paper sx={{
                            p: 2,
                            borderRadius: '12px',
                            bgcolor: 'rgba(255, 255, 255, 0.7)',
                            maxWidth: 'fit-content'
                        }}>
                            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <span role="img" aria-label="chart increasing">↗</span>
                                3x more accurate growth tracking
                            </Typography>
                        </Paper>
                        <Paper sx={{
                            p: 2,
                            borderRadius: '12px',
                            bgcolor: 'rgba(255, 255, 255, 0.7)',
                            maxWidth: 'fit-content'
                        }}>
                            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <span role="img" aria-label="timer">⏱</span>
                                75% less time spent on record keeping
                            </Typography>
                        </Paper>
                        <Paper sx={{
                            p: 2,
                            borderRadius: '12px',
                            bgcolor: 'rgba(255, 255, 255, 0.7)',
                            maxWidth: 'fit-content'
                        }}>
                            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <span role="img" aria-label="chart">📊</span>
                                Instant access to growth percentiles
                            </Typography>
                        </Paper>
                    </Box>
                </Grid>

                {/* Right side - Auth form */}
                <Grid item xs={12} md={6}>
                    <Box sx={{
                        height: '100%',
                        // p: { xs: 4, md: 8 }, // Responsive padding
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start'
                    }}>
                        <Paper sx={{ ...commonStyles.card, p: 4 }}>
                            <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                                {loginOrSignupState === 'login' ? 'Welcome back' : 'Create your account'}
                            </Typography>
                            
                            <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                                {loginOrSignupState === 'login' 
                                    ? 'Sign in to continue to your dashboard'
                                    : 'Get started with tracking your child\'s growth'
                                }
                            </Typography>

                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={handleGoogleLogin}
                                startIcon={<GoogleIcon />}
                                sx={{
                                    ...commonStyles.button.secondary,
                                    py: 1.5,
                                    mb: 3,
                                    borderRadius: '8px'
                                }}
                            >
                                Continue with Google
                            </Button>

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 3
                            }}>
                                <Divider sx={{ flex: 1 }} />
                                <Typography sx={{ px: 2, color: 'text.secondary' }}>OR</Typography>
                                <Divider sx={{ flex: 1 }} />
                            </Box>

                            {error && (
                                <Alert 
                                    severity="error" 
                                    sx={{ mb: 3 }}
                                    onClose={() => setError(false)}
                                >
                                    {errorMessage}
                                </Alert>
                            )}

                            <Box component="form" onSubmit={onAuth}>
                                <TextField
                                    fullWidth
                                    placeholder="Enter your email"
                                    variant="outlined"
                                    sx={{ mb: 3 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon sx={{ color: 'text.secondary' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                                <TextField
                                    fullWidth
                                    type="password"
                                    placeholder="Password"
                                    variant="outlined"
                                    sx={{ mb: 3 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockOpenIcon sx={{ color: 'text.secondary' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => setPassword(e.target.value)}
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        ...commonStyles.button.primary,
                                        py: 1.5,
                                        mb: 3
                                    }}
                                >
                                    {loginOrSignupState === 'login' ? 'Sign in' : 'Create account'}
                                </Button>

                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography color="text.secondary">
                                        {loginOrSignupState === 'login' ? "Don't have an account? " : 'Already have an account? '}
                                        <Link
                                            component="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setLoginOrSignupState(
                                                    loginOrSignupState === 'login' ? 'signup' : 'login'
                                                );
                                                setError(false);
                                            }}
                                            sx={{
                                                color: '#4caf50',
                                                textDecoration: 'none',
                                                fontWeight: 500,
                                                '&:hover': {
                                                    textDecoration: 'underline'
                                                }
                                            }}
                                        >
                                            {loginOrSignupState === 'login' ? 'Sign up' : 'Sign in'}
                                        </Link>
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Auth;