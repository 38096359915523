import { createClient } from "@supabase/supabase-js";

const anonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ6dHl2bHVibW94c25tZ3RjcnpyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMzOTA1MjAsImV4cCI6MjA0ODk2NjUyMH0.li1_hR5vW8fBPkYx-LkJcYfkMnNcUa36NqGoNxGxQI0'
const supabase = createClient("https://rztyvlubmoxsnmgtcrzr.supabase.co", anonKey);

const CreateUser = async (stytch_user_id, user_email) => {
    await supabase.from("users").insert([
        {   
            user_id: stytch_user_id,
            email: user_email,
         },
    ]).select();
}

const CreateUserIfNotExists = async (stytch_user_id, user_email) => {
    const user = await GetUserWithId(stytch_user_id);
    if (user === null || user.length === 0) {
        await CreateUser(stytch_user_id, user_email);
    }
}

const GetUserWithId = async (stytch_user_id) => {
    const { data } = await supabase.from("users").select("*").eq("user_id", stytch_user_id).single();
    return data;
}

const IsPaidUser = async (stytch_user_id) => {
    const user = await GetUserWithId(stytch_user_id);
    if (user['paid_user'] === true) {
        return true;
    }

    return false;
}

const UpdateUserPaymentStatus = async (stytch_user_id, paid_user) => {
    const {data, error} = await supabase.from("users").update({ paid_user: paid_user }).eq("user_id", stytch_user_id).select();
    return {data, error};
}

export { CreateUser, CreateUserIfNotExists, GetUserWithId, IsPaidUser, UpdateUserPaymentStatus };