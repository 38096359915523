// PaymentSuccess.js
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress, Box, Typography } from '@mui/material';
import { GetUserId } from '../services/AuthService';
import { UpdateUserPaymentStatus } from '../services/UserService';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    useEffect(() => {
        const updatePaymentStatus = async () => {
            try {
                const userId = GetUserId();
                sessionStorage.setItem('paid_user', true);
                await UpdateUserPaymentStatus(userId, true);

                // Add 1 second delay to ensure the db is updated
                await new Promise(resolve => setTimeout(resolve, 1000));
                navigate('/dashboard', { replace: true });
            } catch (err) {
                console.error('error updating payment status', err);
                setError(err.message);
            }
        };

        updatePaymentStatus();
    }, [navigate]);

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography color="error">
                    {error}. Please contact support if this persists.
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            gap: 2
        }}>
            <CircularProgress />
            <Typography>
                Confirming your payment...
            </Typography>
        </Box>
    );
}

export default PaymentSuccess;